import React, { useState, useEffect, useCallback } from "react";
import Product from "../../home/Products/Product";
import ProductSkeleton from "../../home/Products/Product-Skeleton";
import { useSelector } from "react-redux";

function Items({ currentItems }) {
  const selectedCurrency = useSelector(
    (state) => state.orebiReducer.selectedCurrency
  );
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item._id} className="w-full h-full bg-black border">
            <Product
              _id={item._id}
              img={item.images.image1}
              img2={item.images.image2}
              img3={item.images.image3}
              name={item.name}
              desc={item.desc}
              size={item.size}
              price={`${
                selectedCurrency === "USD"
                  ? `${item.price_usd}`
                  : `${item.price}`
              }`}
              discountPrice={
                item.discount
                  ? selectedCurrency === "USD"
                    ? item.discount.discountPriceUsd
                    : item.discount.discountPriceIdr
                  : ""
              }
              discountPercentage={item.discount?.discountPercentage}
              discount={item.discount?.isDiscount}
              gender={item.gender}
              type={item.type}
              isAvailable={item.isAvailable}
              isNewArrival={item.isNewArrival}
              isEvent={item.isEvent}
              event={item.event}
            />
          </div>
        ))}
    </>
  );
}

const Pagination = ({
  status,
  error,
  items = [],
  itemsPerPage,
  sorts,
  onSortChange,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0); // Offset to track loaded items
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // Whether there are more items to load

  useEffect(() => {
    if (items.length > 0) {
      resetPagination();
    }
  }, [items, sorts]);

  const resetPagination = () => {
    setItemOffset(0);
    setCurrentItems([]);
    setHasMore(true);
    loadMoreItems(true); // Load fresh items
  };

  const loadMoreItems = useCallback(
    (isReset = false) => {
      if (loading || !hasMore) return;

      setLoading(true);

      // Simulate a delay (1 second)
      setTimeout(() => {
        // Sort items based on the `sorts` state
        const sortedItems = [...items].sort((a, b) => {
          if (sorts.type) {
            return a.type.localeCompare(b.type); // Example: Sorting by type
          }
          return 0; // Default, no sorting
        });

        const startOffset = isReset ? 0 : itemOffset;
        const endOffset = startOffset + itemsPerPage;
        const newItems = sortedItems.slice(startOffset, endOffset);

        setCurrentItems((prev) =>
          isReset ? newItems : [...prev, ...newItems]
        );
        setItemOffset(endOffset);

        if (endOffset >= sortedItems.length) {
          setHasMore(false);
        }

        setLoading(false);
      }, 1000); // 1 second delay
    },
    [loading, hasMore, items, itemOffset, itemsPerPage, sorts]
  );

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Trigger load when scrolling reaches around 80-90% of the page height
    if (scrollPosition >= documentHeight * 0.8 && hasMore && !loading) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  if (status === "pending") {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mdl:gap-4 lg:gap-10">
        <ProductSkeleton counts={10} />
      </div>
    );
  }

  if (error && error.response && error.response.status === 404) {
    return (
      <div className="flex flex-wrap gap-2 justify-start h-52">
        <div className="bg-white flex flex-col justify-center w-full items-center gap-2 py-2 rounded-lg shadow">
          <h4 className="text-xl flex font-extrabold tracking-wide cursor-default">
            Fish Not Found
          </h4>
          <p className="text-base font-normal cursor-default text-center">
            We couldn't find any fish matching your sorting:{" "}
            <span className="capitalize font-semibold italic">
              {sorts.type ? sorts.type : ""}{" "}
              {sorts.gender ? ` & ${sorts.gender}` : ""}
            </span>
          </p>
          <p className="text-sm md:text-base font-normal px-4 text-center opacity-90 italic">
            Please try adjusting your sorting or explore other types or gender.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>

      {/* Loading Skeleton */}
      {loading && (
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mdl:gap-4 lg:gap-10">
          <ProductSkeleton counts={itemsPerPage} />
        </div>
      )}

      {/* No more items message */}
      {!hasMore && !loading && (
        <p className="text-center text-gray-500 mt-4">
          You've reached the end of the list.
        </p>
      )}
    </div>
  );
};

export default Pagination;
